import { LitElement, html } from 'lit-element';

class HomeCollection extends LitElement {

    constructor () {
        super();

        this.events = [];
        this.title = '';
        this.grid = false;
        this.page = 0;
        this.itemsPerPage = 2;
        this.pages = [];
        this.items = [];
        this.featured = false;
    }

    createRenderRoot () {
        /**
        * Render template without shadow DOM. Note that shadow DOM features like
        * encapsulated CSS and slots are unavailable.
        */
        return this;
    }

    static get properties() {
        return {
            events: { type: Array },
            title: { type: String },
            grid: { type: Boolean },
            itemsPerPage: { type: Number },
            featured: { type: Boolean },
        };
    }

    connectedCallback() {
        super.connectedCallback();
        this.getPages();
    }

    updated( changedProperties ) {
        if ( changedProperties.get( 'events' ) ) this.getPages();
    }

    getPages() {
        this.pages = Array( Math.ceil( this.events.length / this.itemsPerPage ) ).fill( 0 ).map( ( item, index ) => index );
        this.page = 0;
        this.requestUpdate();
    }

    selectPage( index ) {
        this.page = index;
        this.requestUpdate();
    }

    render() {
        if ( !this.events ) return html``;

        return html`
            <div class="HomeCollection">
                <div class="row">
                    <h2 class="headline-xl collection-title">${ this.title }</h2>
                    <p class="body-s">${ this.events.length } ${ window.copy.getClientInstance( 'home_collection_results' ) }</p>
                </div>

                <div class="container ${ this.grid ? 'grid' : 'column' }">
                    ${ this.events.slice( this.page * this.itemsPerPage,  this.page * this.itemsPerPage + this.itemsPerPage ).map( ( event ) => html`<home-event .event=${ event } .featured=${ this.featured } .compact=${ this.grid } ></home-event>` )  }
                </div>

                ${ this.pages.length > 1 ? html`
                    <ul class="pagination">
                        <li class="pagination-item pagination-arrow">
                            <button type="button" class="pagination-button body-m" @click=${ () => this.selectPage( this.page - 1 ) } .disabled=${ this.page === 0 }>
                                ${ window.constants.client === 'ford' ? html`
                                    <img class="pagination-icon" src="${ window.constants.cdn }/generic/img/${ window.constants.client }/arrows/arrow-left-grey.svg" />
                                ` : html`
                                    <img class="pagination-icon" src="${ window.constants.cdn }/generic/img/${ window.constants.client }/arrows/arrow-left-blue.svg" />
                                ` }
                            </button>
                        </li>
                        ${ this.pages
                            .reduce( ( acc, next, index ) => {
                                if ( index === 0 || index === this.page - 1 || index === this.page || index === this.page + 1 || index === this.pages.length - 1 ) {
                                    acc.push( next );
                                } else {
                                    if ( acc[ acc.length - 1 ] !== '...' ) acc.push( '...' )
                                }
                                return acc;
                            }, [] )
                            .map( ( item ) => item !== '...' ? html`
                                <li class="pagination-item ${ item === this.page ? 'active' : '' }">
                                    <button type="button" class="pagination-button body-m" @click=${ () => this.selectPage( item ) }>${ item + 1 }</button>
                                </li>
                            ` : html`...` ) }
                        <li class="pagination-item pagination-arrow">
                            <button type="button" class="pagination-button body-m" @click=${ () => this.selectPage( this.page + 1 ) } .disabled=${ this.page === this.pages.length - 1 }>
                                ${ window.constants.client === 'ford' ? html`
                                    <img class="pagination-icon" src="${ window.constants.cdn }/generic/img/${ window.constants.client }/arrows/arrow-right-grey.svg" />
                                ` : html`
                                    <img class="pagination-icon" src="${ window.constants.cdn }/generic/img/${ window.constants.client }/arrows/arrow-right-blue.svg" />
                                ` }
                            </button>
                        </li>
                    </ul>
                ` : '' }
            </div>
        `;
    }
}

customElements.define( 'home-collection', HomeCollection );
